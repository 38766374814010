import { isAnywhereUrl } from '@wix/wix-anywhere-api';
import { ControllerFlowAPI, IWixAPI } from '@wix/yoshi-flow-editor';
import { EXPERIMENTS } from '../constants';
import { toError } from './errors';

export async function isDayful(flowAPI: ControllerFlowAPI, wixCodeApi: IWixAPI) {
  try {
    if (!flowAPI.experiments.enabled(EXPERIMENTS.DAYFUL_SUPPORT)) {
      return false;
    }
    return await isAnywhereUrl({
      externalBaseUrl: wixCodeApi.location.baseUrl,
      fullUrl: wixCodeApi.location.url,
    });
  } catch (e) {
    flowAPI.errorMonitor.captureException(toError(e), {
      contexts: {
        location: {
          url: wixCodeApi.location.url,
          baseUrl: wixCodeApi.location.baseUrl,
        },
      },
    });
  }
  return false;
}
